// Colors
$primary: #00a0b2;
$lightblue: rgba(0, 168, 190, 0.05);
$bluecolor: #2b2951;
$secondary: #e7276f;
$light: #e1e7ea;
$lightgray: #f6f6f6;
$slate-grey: #5c5c6d;
$off-white: #f5f5f3;
$white: #ffffff;
$headings-color: #777779;
$turquoise-blue-light: #ebf8fa;
$charcoal-grey: #485053;
