@import "variables";
@import "intl-tel-input/build/css/intlTelInput.css";
@import url("https://fonts.googleapis.com/css?family=Poppins");

@font-face {
    font-family: "Avant Garde Avant";
    src: url("/fonts/Avant-Garde-Avant.ttf") format("truetype");
}

// Int input flags
.iti__flag {
    background-image: url("/images/vendor/intl-tel-input/build/flags.png");
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .iti__flag {
        background-image: url("/images/vendor/intl-tel-input/build/flags@2x.png");
    }
}

html {
    position: relative;
    min-height: 100%;
}

/****** Universal Changes Design *******/
body {
    font-family: "Lato", sans-serif !important;

    padding-top: 79px;

    /* Media query para pantallas más grandes */
    @media screen and (min-width: 992px) {
        padding-top: 118px; /* Para pantallas mayores o iguales a 992px */
    }
}
h1,
h2 {
    font-family: "PT Serif", serif;
}
.container-fluid {
    padding-right: 0px !important;
    padding-left: 0px !important;
}
/****** Login Page *******/

.right-container {
    background-color: #ffffffcc;
}
.auth-background {
    background-image: url("/images/login-bg.jpg");
}
.auth-heading {
    font-weight: 700;
    font-size: 45px;
    color: $bluecolor;
    font-family: "Avant Garde Avant", sans-serif;
}
.login-icon {
    $width: 30px;
    width: $width;
    height: $width;
    position: absolute;
    right: 15px;
    top: 49px;
}
.login-btn {
    background-color: $white !important;
    box-shadow: 0 2px 2px 0 rgba(105, 105, 105, 0.5);
}
.modal-para {
    font-size: 15pt;
    color: $headings-color;
}
.modal-custom-header {
    border: none !important;
}
button.btn-close {
    position: absolute;
    top: 30px;
    right: 30px;
    border: 3px solid $bluecolor;
    border-radius: 50%;
    font-size: 16px;
    color: $bluecolor;
}
.login-field {
    background-color: $white !important;
}
.login-label {
    font-size: 16pt;
    font-weight: 600;
    color: $bluecolor;
}
/****** Menu Design *******/

nav.navbar {
    background-color: $off-white;

    .form-control-borderless {
        border: none;
    }

    .form-control-borderless:hover,
    .form-control-borderless:active,
    .form-control-borderless:focus {
        border: none;
        outline: none;
        box-shadow: none;
    }

    /**
    Logo fix for screens between 768 and 1024
     */
    img.logo {
        @media screen and (min-width: 992px) and (max-width: 1200px) {
            width: 55px;
            height: 55px;
            object-fit: cover;
            object-position: 0;
        }
    }
}
a.navbar-brand {
    color: $white !important;
}
a.nav-link {
    color: $bluecolor !important;
    font-size: 17px !important;
    font-weight: 400;
}
li.nav-item {
    margin-right: 15px;
}
.search-outer {
    $width: 100%;
    width: $width;
}
.search-outer > input {
    padding-left: 35px;
}
.search-icon {
    position: relative;
    top: 7px;
    left: 32px;
}

/****** Slider Design *******/
.carousel-inner {
    min-height: 400px;
    background: $lightblue;
}
.slider-text-small {
    font-weight: 300;
    color: white;
    font-size: 20px;
}
.slider-text {
    font-weight: 300;
    color: white;
    font-size: 20px;
}
.slider-heading {
    font-weight: 700;
    font-size: 45px;
    color: white;
    font-family: "Poppins", sans-serif;
}
section#slider .row {
    margin: 0px;
}
.col-md-12.slider-item-container {
    padding: 0px !important;
}
.card-product,
.card-artisan {
    width: 100%;
    border: none !important;
    border-radius: 8px !important;
    box-shadow: 0 4px 8px 0 rgba(96, 97, 112, 0.16),
        0 0 2px 0 rgba(40, 41, 61, 0.04);
}
/****** Category Heading *******/
.heading {
    font-family: "Avant Garde Avant", sans-serif;
    font-size: 26px;
    color: $headings-color;
}
/****** Category Card *******/
section.categories {
    h1 {
        color: $bluecolor;
    }
    a {
        color: $charcoal-grey;
        text-decoration: none;
        font-size: 21px;
    }
}
.fav-icon {
    position: absolute;
    right: 10px;
    top: 10px;
}
/****** Grid  *******/
.grid-item {
    max-height: 300px;
}
.grid-item-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
img.cat-img {
    max-height: 300px;
}
/****** Contact Area *******/
.light-background {
    background-color: $lightgray;
}

/****** Icons *******/

.icon {
    $size: 25px;
    width: $size;
    height: $size;
}

/**************** Categories Page *********************/

/****** Filters Section *******/
.filtersAccordion {
    .accordion-item,
    .accordion-button {
        border: none;
        background-color: transparent;
        font-family: "Poppins", sans-serif;
    }
}
.filter-heading {
    font-family: "Poppins", sans-serif;
    font-size: 24px;
    font-weight: 400;
    color: $headings-color;
}
.filter-item-heading {
    font-family: "Avant Garde Avant", sans-serif;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 600;
    color: $bluecolor;

    button {
        font-size: 18px !important;
    }
}
.filter-checkbox {
    $width: 1.2em;
    width: $width !important;
    height: $width !important;
}
.filter-caps {
    padding: 5px 25px !important;
}
.filter-label {
    font-size: 18px;
    color: $headings-color;
}
.btn-sidebar {
    background-color: $bluecolor !important;
    color: $white !important;
}

/**
Products loop
 */
.product {
    a {
        color: inherit;
        text-decoration: none;
    }
    .sale-price {
        color: $charcoal-grey;
    }
    .actual-price {
        color: $light;
        text-decoration: line-through;
    }
    img {
        object-fit: contain;
    }
}

.product,
.product-details {
    .wishlist {
        width: 25px;
        height: 25px;
        position: absolute;
        background-repeat: no-repeat;
        background-image: url("/images/like.svg");
        -webkit-transition: background-image 0.2s ease-in-out;
        transition: background-image 0.2s ease-in-out;
        &.liked {
            background-image: url("/images/liked.svg") !important;
        }
    }
}

/****** Ads Section *******/
.ads-item {
    height: 180px;
}
.ad-item-heading {
    font-family: "Poppins", sans-serif;
    font-size: 16pt;
    font-weight: 400;
    color: $bluecolor;
}

/**************** Categories Page *********************/
.categories-page {
    //background-color: $turquoise-blue-light;
}
.page-heading {
    font-weight: 700;
    font-size: 45px;
    color: $bluecolor;
    font-family: "Avant Garde Avant", sans-serif;
}
.page-subheading {
    font-weight: 300;
    color: $bluecolor;
    font-size: 20px;
    font-family: "Poppins", sans-serif;
}
/****** Tags Section *******/

.filter-caps {
    border-radius: 25px !important;
}
.filter-search-item {
    border-radius: 25px !important;
}
/**************** Brands Page *********************/
/****** Brand Section *******/
.brand-image {
    border: 1px solid rgb(215, 215, 215) !important;
}
.brands-subtitle {
    color: rgb(92, 92, 109);
}
.brands-description {
    color: rgb(92, 92, 109);
    text-align: justify;
}
.brand-box {
    min-height: 100px;
    background-color: $lightgray;
}
.icon-container {
    border-radius: 50%;
    height: 70px;
    width: 70px;
    background: rgb(227, 227, 227);
    text-align: center;
    line-height: 50px;
    font-size: 28px;
}
.brand-heading {
    font-family: "Avant Garde Avant", sans-serif;
    font-size: 17pt;
    color: $bluecolor;
    font-weight: 600;
}
.brand-para {
    font-size: 12px;
    color: $bluecolor;
}
.brand-icon {
    font-size: 35px;
    color: $bluecolor;
}
/**************** Product Page *********************/
/****** Product Top Section *******/
.product-details {
    .slick-slide {
        margin: 20px 0;
    }
    .product-title {
        font-size: 20px;
        color: $charcoal-grey;
        font-family: "Avant Garde Avant", sans-serif;
    }
    .sale-price {
        font-size: 40px;
        color: $charcoal-grey;
    }
    .actual-price {
        font-size: 24px;
        color: $light;
        text-decoration: line-through;
    }
    .input-quantity {
        min-width: 55px !important;
        flex: 0 !important;
    }
    .divider {
        height: 3px !important ;
        background: linear-gradient(
            90deg,
            rgba(43, 41, 81, 0.11388305322128855) 0%,
            rgba(199, 199, 199, 1) 50%,
            rgba(43, 41, 81, 0.03265056022408963) 100%
        );
    }
    .product-item-heading {
        font-family: "Poppins", sans-serif;
        font-size: 16px;
        color: $bluecolor;
        font-weight: 600;
    }
    .product-item-property {
        font-size: 16px;
        color: $bluecolor;
        font-weight: 300;
    }

    .btn-check:checked + .btn-outline-light {
        color: $secondary !important;
        border-color: $secondary !important;
        background-color: $white !important;
    }
}

.orange-decoration {
    margin: 20px 0;
    height: 40px;
    background-image: url("/images/hr-bg.png");
    background-repeat: repeat-x;
}

/**
Embed container videos
 */
.embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
}
.embed-container iframe,
.embed-container object,
.embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/**
Sponsored badge
 */
.sponsored {
    width: 25px;
    height: 25px;
    position: absolute;
    right: 0;
    margin: 15px;
    background-repeat: no-repeat;
    background-image: url("/images/sponsored.png");
    background-size: contain;
}

/**
Carousel
 */
.slick-prev:before,
.slick-next:before {
    color: $bluecolor;
}

/**
Contact
 */
#contact {
    h1 {
        color: $bluecolor;
    }
    label {
        color: $slate-grey;
    }
}
/**
Footer
 */
.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 200px; /* Altura del footer */
    background-color: #f5f5f5;
    background-image: url("/images/footer.webp");
    background-size: cover;
    background-repeat: repeat-y;
}

.slider-nav img {
    margin: 5px;
    cursor: pointer;
    object-fit: cover;
    width: 100%;
}
.slider-main img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transition: opacity 0.3s ease-in-out;
}

/* Estilo para la vista móvil */
@media (max-width: 768px) {
    .slider-nav {
        position: relative;
        margin-top: 20px; /* Espacio entre la columna de miniaturas y la imagen principal */
    }
    .slider-main {
        height: 300px; /* Altura fija para la imagen principal en vista móvil */
    }
}

@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.7.2/font/bootstrap-icons.css");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=PT+Serif:wght@400;700&display=swap");
@import "../../node_modules/bootstrap/scss/bootstrap.scss";
