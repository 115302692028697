@import url("https://fonts.googleapis.com/css?family=Poppins");

@font-face {
    font-family: "Avant Garde Avant";
    src: url("/fonts/Avant-Garde-Avant.ttf") format("truetype");
}

.detail-button {
    background-color: #fff;
    color: #000;
    border: 1px solid #4c4184;
    border-radius: 5px;
    transition-duration: 0.4s;
}

.detail-button:hover {
    background-color: #4c4184;
    color: #fff;
}

.my-link:hover {
    color: #fff;
}

.font-poppins {
    font-family: "Poppins", sans-serif;
}

.font-avant {
    font-family: "Avant Garde Avant", sans-serif;
}

/* Chrome, Safari, Edge, Opera */
input[name="shipping_zip_code"]::-webkit-outer-spin-button,
input[name="shipping_zip_code"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[name="shipping_zip_code"][type="number"] {
    -moz-appearance: textfield;
}

/* Chrome, Safari, Edge, Opera */
input[name="zip_code"]::-webkit-outer-spin-button,
input[name="zip_code"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[name="zip_code"][type="number"] {
    -moz-appearance: textfield;
}

/* Chrome, Safari, Edge, Opera */
input[name="userzipcode"]::-webkit-outer-spin-button,
input[name="userzipcode"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[name="userzipcode"][type="number"] {
    -moz-appearance: textfield;
}

/* Chrome, Safari, Edge, Opera */
input[name="billing_zip_code"]::-webkit-outer-spin-button,
input[name="billing_zip_code"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[name="billing_zip_code"][type="number"] {
    -moz-appearance: textfield;
}

/* Chrome, Safari, Edge, Opera */
input[name="quantity"]::-webkit-outer-spin-button,
input[name="quantity"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[name="quantity"][type="number"] {
    -moz-appearance: textfield;
}
